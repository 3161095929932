import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['button'];
  }

  connect() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        this.buttonTarget.classList.add('bottom-10', 'opacity-1');
        this.buttonTarget.classList.remove('opacity-0', 'bottom-0');
      } else {
        this.buttonTarget.classList.add('opacity-0', 'bottom-0');
        this.buttonTarget.classList.remove('bottom-10', 'opacity-1');
      }
    });
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
