import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static get targets() {
    return ['tab'];
  }

  initialize() {
    window.addEventListener('popstate', () => this.tabChanged());
  }

  tabChanged() {
    Turbo.visit(document.location);
  }

  connect() {
    this.activeTabStyles = this.data.get('activeTabStyles').split(' ');
    this.currentTab.classList.add(...this.activeTabStyles);
  }

  setActiveTab(event) {
    this.tabTargets.forEach((tab) => {
      if (tab.isEqualNode(event.target)) {
        tab.classList.add(...this.activeTabStyles);
      } else {
        tab.classList.remove(...this.activeTabStyles);
      }
    });

    this.updateTabParam(event);
  }

  updateTabParam(event) {
    const url = new URL(window.location);
    url.searchParams.set('tab', event.target.dataset['tabId']);
    window.history.replaceState(window.history.state, '', url);
  }

  get currentTab() {
    const currentTabId =
      new URLSearchParams(window.location.search).get('tab') ||
      this.data.get('defaultTabId');
    return this.tabTargets.find((tab) => tab.dataset['tabId'] == currentTabId);
  }
}
