import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';

// marietta, ga
const DEFAULT_CENTER = {
  lat: 33.9484548,
  lng: -84.5835713,
};

// wonder if there is a way we could use view components here?
const buildInfoWindow = ({ name, location, href, logo }) => {
  return `
    <a href="${href}" target="_blank" data-turbo-frame="_top">
      <div class="font-rubik bg-white pt-4 pb-1 hover:underline flex flex-row">
        <div class="ml-1 mr-2">
          <div class="flex space-x-[-20px]">
            <div class="flex items-center justify-center w-12 h-12 rounded-full ring-2 ring-zinc-200 bg-zinc-100 overflow-hidden">
              <img src="${logo}" />
            </div>
          </div>
        </div>
        <div>
          <h3 class="font-medium text-lg leading-tight text-zinc-800">${name}</h3>
          <p>${location}</p>
        </div>
      </div>
    </a>
  `;
};

export default class extends Controller {
  static targets = ['map', 'company'];
  static values = {
    apiKey: String,
    city: String,
    mapId: String,
  };

  async connect() {
    this.initLoader();
    await this.buildMap();
    await this.addMarkers();
  }

  initLoader() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: 'weekly',
    });
  }

  async buildMap() {
    const { Map, InfoWindow } = await this.loader.importLibrary('maps');
    const { ControlPosition } = await this.loader.importLibrary('core');
    this.infoWindow = new InfoWindow();

    let center = DEFAULT_CENTER;
    let geometry;
    const result = await this.geocodeAddress(this.cityValue);
    if (result.results.length) {
      geometry = result.results[0].geometry;
      center = geometry.location;
    }

    this.map = new Map(this.mapTarget, {
      center,
      zoom: 12,
      maxZoom: 15,
      mapId: this.mapIdValue,
      disableDefaultUI: true,
      clickableIcons: false,
      zoomControl: true,
      zoomControlOptions: {
        position: ControlPosition.RIGHT_TOP,
      },
    });

    // close info window if click anywhere outside of it
    this.map.addListener('click', () => {
      this.infoWindow.close();
    });

    // changes map zoom so the entire city fits on the visible map
    if (geometry) {
      this.map.fitBounds(geometry.bounds);
    }
  }

  async addMarkers() {
    const { AdvancedMarkerElement, PinElement } =
      await this.loader.importLibrary('marker');

    this.companyTargets.map(async (company) => {
      // If we had the lat/lng stored in the db (get when address is changed?) this would be
      // faster and cheaper
      const {
        addressline1,
        addressline2,
        city,
        state,
        postalcode,
        name,
        verified,
        location: companyLocation,
        href,
        logo,
      } = company.dataset;

      const isVerified = verified === 'true';

      if (!addressline1 || !city || !state) return;

      // do this better
      let addressString;
      if (addressline2) {
        addressString = `${addressline1} ${addressline2}`;
      } else {
        addressString = `${addressline1}`;
      }
      addressString = `${addressString} ${city}, ${state}`;

      if (postalcode) {
        addressString = `${addressString} ${postalcode}`;
      }

      const result = await this.geocodeAddress(addressString);

      if (result.results.length) {
        const location = result.results[0].geometry.location;

        const marker = new AdvancedMarkerElement({
          map: this.map,
          position: location,
          title: name,
          content: new PinElement({
            background: isVerified ? '#8b5cf6' : '#27272A',
            borderColor: isVerified ? '#8b5cf6' : '#27272A',
            glyphColor: '#fff',
          }).element,
          gmpClickable: true,
        });

        marker.content.addEventListener('mouseenter', () => {
          this.infoWindow.close();
          this.infoWindow.setContent(
            buildInfoWindow({ name, location: companyLocation, href, logo }),
          );
          this.infoWindow.open(marker.map, marker);
        });
      }
    });
  }

  async geocodeAddress(address) {
    const { Geocoder } = await this.loader.importLibrary('geocoding');
    const geocoder = new Geocoder();
    const result = await geocoder.geocode({ address: address });

    return result;
  }
}
