import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['alert'];
  }

  static get values() {
    return {
      duration: {
        type: Number,
        default: 3000,
      },
    };
  }

  show() {
    this.alertTarget.hidden = false;
    setTimeout(() => {
      this.alertTarget.hidden = true;
    }, this.durationValue);
  }
}
